body {
  width: fit-content;
  background: #a2b8a5;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Greeting (White area) */
.Greeting {
  width: 100%;
}

.greet-cont {
  background: white;
  margin: 0% 7% 2% 7%;
}

.greet-grid-cont {
  display: flex;
  flex-wrap: wrap-reverse;
  padding: 2%;
}

.left-small {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.left-large {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.greet-pic {
  width: 50%;
  min-width: 300px;
  max-width: 300px;
  margin-right: 2%;
}

.right{
  flex-grow: 2;
  justify-content: flex-start;
}

h1 {
  text-align: left;
	display: inline;
	position: relative;
	font: 200px Helvetica, Sans-Serif;
	letter-spacing: -5px;
	color: rgba(0,0,255,0.5); 
}

h1:after {
	content: "Hello.";
	position: absolute; left: 10px; top: 5px;
	color: rgba(255,0,0,0.5);   
}

.left-name {
  font: 30px Helvetica, Sans-Serif;
  font-weight: bold;
  padding-top: 10px;
}

.right-name {
  font: 50px Helvetica, Sans-Serif;
  font-weight: bold;
}

.bio {
  margin-top: 30px;
  margin-bottom: 30px;
  font: 30px Helvetica, Sans-Serif;
}


/* Navigation bar */
.nav-grid-cont-large {
  margin: 5% 17% 1% 7%;
  display: flex;
  grid-template-columns: auto auto auto auto auto;
  justify-content: flex-end;
  align-items: flex-end;
}

.nav-grid-cont-small {
  margin: 5% 7% 1% 7%;
  display: flex;
  grid-template-columns: auto auto auto auto auto;
  justify-content: flex-end;
  align-items: flex-end;
}

.nav-button {
  margin-left: 2%;
}



a {
  color: white;
  font: 30px Helvetica, Sans-Serif;
  font-weight: bold;
  text-decoration: none;
}

.a-large {
  font: 50px Helvetica, Sans-Serif;
  font-weight: bold;
  padding-left: 0;
}

/* Cards */

.card {
  margin: 2% 17% 2% 17%;
}

.card-header  {
  text-align: left;
}

.line {
  height: 20px;
  width: 100%;
}

.card-header p{
  text-align: justify;
  width: 100%;
}

/* Footer */

.react-grid {
  display: grid;
  grid-template-columns: 70px auto;
  padding-left: 150px;
  background: #071717;
}

.react-left {
  font: 12px Helvetica, Sans-Serif;
  color: white;
  margin-top: 18px;
}

.react-logo {
  height: 50px;
}


.growbot-img-cont {
  margin: 2% 17% 2% 17%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.secure-img-cont {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pc {
  height: 100px;
  margin-top: 5px;
}